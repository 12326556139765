"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bot = void 0;
var vanilla_bot_1 = require("./modules/fitki/vanilla-bot");
var object_service_1 = require("./services/object-service");
require("./services/translation");
var Bot = /** @class */ (function () {
    function Bot(settings) {
        var url = window.location.href;
        var params = new URLSearchParams(window.location.search);
        if (url.includes('topfit.fitness')) {
            //if (params.get('debug') === 'true') { 
            object_service_1.ObjectService.getInstance(vanilla_bot_1.VanillaBot).loadModule(settings);
            //}
        }
        else {
            object_service_1.ObjectService.getInstance(vanilla_bot_1.VanillaBot).loadModule(settings);
        }
    }
    return Bot;
}());
exports.Bot = Bot;
console.log('---Bot initialized---');
// @ts-ignore
window === null || window === void 0 ? void 0 : window.fittecBot = Bot;
// document.addEventListener('DOMContentLoaded', (event) => {
//   ObjectService.getInstance(Bot);
// });
